<style scoped lang="less">
.t_guide_student {
  box-shadow: @shadow;
  background: #fff;
  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }
  .content {
    padding: 20px;
  }
}
</style>
<template>
  <div class="t_guide_student">
    <div class="stu-module-header">
      <div class="stu-module-title">开题审核</div>
    </div>
    <div class="content">
      <el-button @click="query" style="margin-bottom: 15px" type="primary"
        >查询</el-button
      >
      <el-button @click="pass" style="margin-bottom: 15px" type="primary"
        >驳回</el-button
      >
      <el-form :model="form" size="medium" inline>
        <el-form-item label="姓名/学号">
          <el-input v-model="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="开题报告">
          <el-input v-model="form.report" clearable></el-input>
        </el-form-item>
        <el-form-item label="毕业批次">
          <el-select v-model="form.batch" :clearable="true">
            <el-option
              v-for="(item, index) in batchOptions"
              :key="index"
              :label="item.batchName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核状态">
          <el-select v-model="form.auditState" :clearable="true">
            <el-option
              v-for="(item, index) in auditStateOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        :height="height"
        @selection-change="selectionChange"
        stripe
      >
        <el-table-column
          type="selection"
          width="65"
          align="center"
          fixed
        ></el-table-column>
        <el-table-column label="序号" width="65" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (current - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in column"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :min-width="item.width"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="审核" prop="" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="audit(scope.row, scope.$index)">
              查看审核
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="tableData.length"
      >
      </el-pagination>
    </div>
    <AuditDrawer @httpOk="httpOk" ref="t_aduit_drawer" />
  </div>
</template>

<script>
import AuditDrawer from "./module/AuditDrawer.vue";
export default {
  name: "t_guide_student",
  components: { AuditDrawer },
  data() {
    return {
      height: window.innerHeight - 380,
      form: {
        name: null,
        report: null,
        batch: null,
        auditState: null,
      },
      batchOptions: [],
      auditStateOptions: [
        { label: "待审核", value: "0" },
        { label: "审核通过", value: "1" },
        { label: "审核驳回", value: "2" },
      ],
      column: [
        { label: "姓名", prop: "studentName" },
        { label: "学号", prop: "studentNo" },
        { label: "专业", prop: "profName" },
        { label: "联系电话", prop: "contactPhone" },
        { label: "提交时间", prop: "submitTime" },
        { label: "标题", prop: "title" },
        { label: "审核状态", prop: "checkStatuss" },
      ],
      selectionData: [],
      tableData: [{}],
      total: 0,
      pageSize: 20,
      current: 1,
    };
  },
  mounted() {
    this.init();
    this.selectionInit();
  },
  methods: {
    httpOk(){
      this.init();
    },
    pass() {
      if (this.selectionData.length == 0){
        return this.$message.error("请选择数据")
      }
      this.openLoadingView();
      let idArray = [];
      for (let i = 0; i < this.selectionData.length ; i++){
        idArray.push(this.selectionData[i].id);
      }
      let ids = idArray.toString();
      let data = {
        ids: ids,
        checkStatus: 2,
      }
      this.$axios_supermall.post("/hnjxjy-core/eduProposal/updateStuCheck",data).then((resp) => {
        if (resp.data.code == 2000) {
          this.$message.success("操作成功");
          this.init();
        }else {
          this.$message.error("操作失败");
          this.loadingView.close();
        }
      }).catch((e)=>{
        this.$message.error(e)
        this.loadingView.close();
      })
    },
    init() {
      this.openLoadingView();
      this.$axios_supermallData({
        url: "/hnjxjy-core/eduProposal/queryStuAll",
        method: 'get',
        params: {
          page: this.current,
          pageSize: this.pageSize
        }
      }).then((resp) => {
        if (resp.data.code == 2000) {
          this.total = resp.data.data.total;
          this.tableData = resp.data.data.list;
          this.loadingView.close();
        }else {
          this.loadingView.close();
        }
      }).catch((e)=>{
        this.$message.error(e);
        this.loadingView.close();
      })
    },
    selectionInit(){
      this.openLoadingView();
      this.$axios_supermallData.get("/hnjxjy-core/eduGraduateDesignBatch/queryEduGraduateDesignBatch").then((resp)=>{
        if (resp.data.code == 2000) {
          this.batchOptions = resp.data.data;
          this.loadingView.close();
        }else {
          this.loadingView.close();
        }
      }).catch((e)=>{
        this.$message.error(e);
        this.loadingView.close();
      })
    },
    query() {
      this.openLoadingView();
      this.$axios_supermallData({
        url: "/hnjxjy-core/eduProposal/queryStuAll",
        method: 'get',
        params: {
          page: this.current,
          pageSize: this.pageSize,
          batchId: this.form.batch,
          studentNameOrNum: this.form.name,
          title: this.form.report,
          checkStatus: this.form.auditState,
        }
      }).then((resp) => {
        if (resp.data.code == 2000) {
          this.total = resp.data.data.total;
          this.tableData = resp.data.data.list;
          this.loadingView.close();
        }else {
          this.loadingView.close();
        }
      }).catch((e)=>{
        this.$message.error(e);
        this.loadingView.close();
      })
    },
    audit(row, index) {
      this.$refs["t_aduit_drawer"].drawer = true;
      setTimeout(() => {
        this.$refs["t_aduit_drawer"].id = row.id;
        this.$refs["t_aduit_drawer"].form = {
          date: "",
          title: "",
          desc: null,
          content: null,
          auditState: 1,
          remark: null, // 审核评语
        };
        this.openLoadingView();
      }, 100);
      setTimeout(() => {
        this.$refs["t_aduit_drawer"].query();
        this.loadingView.close();
      }, 1000);

    },
    selectionChange(val) {
      this.selectionData = val;
    },
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.init();
      }
    },
  },
};
</script>
