<style lang="less" scoped>
.audit_drawer {
  .el-form {
    padding: 0 20px;
  }
}
</style>
<template>
  <el-drawer
    :visible.sync="drawer"
    size="50%"
    title="审核"
    class="audit_drawer"
  >
    <el-form :model="form" size="medium">
      <el-form-item label="提交时间：">
        <span>{{ form.checkTime }}</span>
      </el-form-item>
      <el-form-item label="论文题目：">
        <span>{{ form.title }}</span>
      </el-form-item>
      <el-form-item label="文献综述：">
        <p>{{ form.describe }}</p>
      </el-form-item>
      <el-form-item label="内容：">
        <p>{{ form.content }}</p>
      </el-form-item>
      <el-form-item label="审核状态：">
        <el-select v-model="form.checkStatus" style="max-width: 200px;">
          <el-option
            v-for="(item, index) in auditStateOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="审核评语：">
        <el-input
          v-model="form.comment"
          type="textarea"
          :maxlength="800"
          show-word-limit
          placeholder="请输入内容"
          :autosize="{ minRows: 4, maxRows: 6 }"
        ></el-input>
      </el-form-item>
      <el-button @click="next" type="primary">审核下一份</el-button>
      <el-button @click="save" type="primary">保存审核</el-button>
    </el-form>
  </el-drawer>
</template>

<script>
export default {
  name: "audit_drawer",
  data() {
    return {
      id: null,
      drawer: false,
      form: {
        checkTime: "",
        title: "",
        describe: null,
        content: null,
        checkStatus: 1,
        comment: null, // 审核评语
      },
      auditStateOptions: [
        { label: "待审核", value: 0 },
        { label: "审核通过", value: 1 },
        { label: "审核驳回", value: 2 },
      ],
    };
  },
  methods: {
    query(){
      this.$axios_supermallData.get("/hnjxjy-core/eduProposal/queryEduProById?id="+this.id)
      .then(resp => {
        if (resp.data.code === 2000){
          this.form = resp.data.data;
        }else {

        }
      }).catch(err => {
        this.$message.error(err)
      });
    },
    save() {
      this.openLoadingView();
      let data = {
        ids: this.id,
        checkStatus: this.form.checkStatus,
        comment: this.form.comment
      }
      this.$axios_supermall.post("/hnjxjy-core/eduProposal/updateStuCheck",data).then((resp) => {
        if (resp.data.code == 2000) {
          this.$message.success("保存成功");
          this.$emit('httpOk')
        }else {
          this.$message.error("保存失败");
          this.loadingView.close();
        }
      }).catch((e)=>{
        this.$message.error(e)
        this.loadingView.close();
      })
      this.drawer = false;
    },
    next() {
      this.drawer = false;
    },
  },
};
</script>
